import React, { useReducer, useContext, useEffect } from "react";
import jwtInterceptor from "../components/shared/jwtInterceptor";
import AuthContext from "../components/shared/AuthContext";
import { Button, Tooltip, Whisper, CustomProvider, DatePicker, RadioGroup, Radio, FlexboxGrid, Uploader, Notification, useToaster } from "rsuite";
import ruRU from 'rsuite/locales/ru_RU';
import "rsuite/dist/rsuite.min.css";

const url = process.env.NODE_ENV === "production" ? '/' : 'https://mgs01.cameracontrol.ru/'

const CamContext = React.createContext(null);

function VidBlock({cn}) {
  const { cameras, setCameras, toaster, errmessage, errastart, erraend, erraneg, erralarge, okmessage, setLoading, fetchState } = useContext(CamContext);
  const { token } = useContext(AuthContext);
  const fetchSwitch = async (name, state, start = null, end = null) => {
    //setLoading(true);
    if (start) {
      start = new Date(start.getTime() - start.getTimezoneOffset()*60000)
      start = start.toISOString().split("-").join("").split(":").join("").slice(0,15).concat("Z");
    }
    if (end) {
      end = new Date(end.getTime() - end.getTimezoneOffset()*60000)
      end = end.toISOString().split("-").join("").split(":").join("").slice(0,15).concat("Z");
    }
    const response = await jwtInterceptor.post('switchstate', {name: name, state: state, start: start, end: end})
    if (!response.data) {
      //setLoading(false)
      toaster.push(errmessage, {placement: 'bottomEnd'})
    } else {
      //setLoading(false);
      toaster.push(okmessage, {placement: 'topCenter'})
    }
  }

  const fetchArchive = async (name, astart, aend) => {
    setLoading(true);
    astart = new Date(astart.getTime() - astart.getTimezoneOffset()*60000)
    astart = astart.toISOString().slice(0,19).concat("Z");
    aend = new Date(aend.getTime() - aend.getTimezoneOffset()*60000)
    aend = aend.toISOString().slice(0,19).concat("Z");

    const response = await jwtInterceptor.post('getarchive', {name: name, astart: astart, aend: aend})
    if (!response.data) {
      setLoading(false)
      toaster.push(errmessage, {placement: 'bottomEnd'})
    } else {
      setLoading(false);
      toaster.push(okmessage, {placement: 'topCenter'})
      fetchState()
    }
  }

  const tooltip = (d) => {
    return (
    <Tooltip>
      Последнее изменение<br /><i>{d}</i>
    </Tooltip>
    );
  };
  const toggle = (checked, name) => {
    setCameras({ [name] : {...cameras[name], state: checked} })
    fetchSwitch(cameras[name]['name'], checked, cameras[name]['start'], cameras[name]['end']).catch((e)=>{toaster.push(errmessage, {placement: 'bottomEnd'})})
  };
  const handleDate = (date, name, type) => {
    //console.log(date)
    if (date<new Date()) {
      setCameras({ [name] : {...cameras[name], [type]: date} })
    }
  };
  const handleArchive = (name) => {
    if (!cameras[name]['astart']) {
      toaster.push(errastart, {placement: 'topCenter'});
      return;
    }
    if (!cameras[name]['aend']) {
      toaster.push(erraend, {placement: 'topCenter'});
      return;
    }
    if (cameras[name]['astart'] > cameras[name]['aend']) {
      toaster.push(erraneg, {placement: 'topCenter'});
      return;
    }
    if (cameras[name]['aend'].valueOf()-cameras[name]['astart'].valueOf()>2*1000*60*60) {
      toaster.push(erralarge, {placement: 'topCenter'});
      return;
    }
    fetchArchive(cameras[name]['name'], cameras[name]['astart'], cameras[name]['aend']);
  }

  const UploaderLocale = {
    inited: 'Начало',
    progress: 'Выгрузка',
    error: 'Ошибка',
    complete: 'Завершенно',
    emptyFile: 'Пусто'
  }
  return (
    <div>
    <hr />
    <FlexboxGrid justify="center">
    <FlexboxGrid.Item colspan={7} className="bigcell">
      <div>{cameras[cn].url}</div>
    </FlexboxGrid.Item>
    <FlexboxGrid.Item colspan={5} className="bigcell">
      <div>
        <RadioGroup name="radio1" value={cameras[cn].state} onChange={(checked) => toggle(checked, cn)}>
          <Radio value="-1">Выключен</Radio>
          <Radio value="1" disabled={ cameras[cn].virtual ? true : false }>Оригинальный поток</Radio>
          <Radio value="2" disabled={ !cameras[cn].start || cameras[cn].virtual ? true : false }>Оригинальный поток (архив)</Radio>
          <Radio value="3">Подменный поток из файла</Radio>
        </RadioGroup>
      </div>
    </FlexboxGrid.Item>
    <FlexboxGrid.Item colspan={4} className="normcell">
      <div>&nbsp;</div>
      <div>&nbsp;</div>
      <div><label>С: </label><CustomProvider locale={ruRU}><DatePicker onChange={(date) => handleDate(date, cn, 'start')} value={cameras[cn].start} format="yyyy-MM-dd HH:mm:ss" /></CustomProvider></div>
      <div><label>С: </label><CustomProvider locale={ruRU}><DatePicker onChange={(date) => handleDate(date, cn, 'astart')} value={cameras[cn].astart} format="yyyy-MM-dd HH:mm:ss" /></CustomProvider></div>
      <div>
        <Whisper placement="top" controlId="control-id-hover" trigger="hover" speaker={tooltip(cameras[cn].filedate)}>
          <a target="_blank" rel="noreferrer" href={`${url}uploads/video_${cameras[cn].name}.avi`} style={ !cameras[cn].file ? { pointerEvents: 'none', cursor: 'default', textDecoration: 'line-through' } : {}}>video_{cameras[cn].name}.avi</a>
        </Whisper>{ cameras[cn].file ? ` (${cameras[cn].file} Мб)` : '' }
      </div>
    </FlexboxGrid.Item>
    <FlexboxGrid.Item colspan={8} className="normcell">
      <div>&nbsp;</div>
      <div>&nbsp;</div>
      <div><label>По: </label><CustomProvider locale={ruRU}><DatePicker onChange={(date) => handleDate(date, cn, 'end')} value={cameras[cn].end} format="yyyy-MM-dd HH:mm:ss" /></CustomProvider></div>
      <div><label>По: </label><CustomProvider locale={ruRU}><DatePicker onChange={(date) => handleDate(date, cn, 'aend')} value={cameras[cn].aend} format="yyyy-MM-dd HH:mm:ss" /></CustomProvider><Button onClick={(e) => handleArchive(cn)}>Скачать с регистратора</Button></div>
      <div><Uploader headers={{'Authorization': 'Bearer ' + token}} onSuccess={() => fetchState()} name={cameras[cn].name} action={`${url}upload`} locale={{...UploaderLocale, ...{upload: `Загрузить видео ${cameras[cn].name}`}}} /></div>
    </FlexboxGrid.Item>
  </FlexboxGrid>
  </div>
  )
}

const Automate = () => {
  const { setLoading, setVersion, setCompany } = useContext(AuthContext)

  const toaster = useToaster();
  const errmessage = (
    <Notification type="error" header="Ошибка">При получения данных с сервера</Notification>
  );
  const errastart = (
    <Notification type="error" header="Ошибка">Не указано время начала получения данных</Notification>
  );
  const erraend = (
    <Notification type="error" header="Ошибка">Не указано время конца получения данных</Notification>
  );
  const erraneg = (
    <Notification type="error" header="Ошибка">Время начала выгрузки должно быть раньше, чем время конца выгрузки</Notification>
  );
  const erralarge = (
    <Notification type="error" header="Ошибка">Разница времени между началом и концом выгрузки превышает 2 часа</Notification>
  );
  const okmessage = (
    <Notification type="success" header="Успешно">Данные отправлены на сервер</Notification>
  );

  const reducer = (prevState, updatedProperty) => ({
    ...prevState,
    ...updatedProperty,
  });
  const [cameras, setCameras] = useReducer(reducer,{})

  const fetchState = async () => {
    setLoading(true);
    const response = await jwtInterceptor.post('getstate')
    if (!response.data) {
      setLoading(false);
      toaster.push(errmessage, {placement: 'bottomEnd'})
    } else {
      setLoading(false);
      setCompany(response.data[Object.keys(response.data)[0]]['company'])
      setVersion(response.data[Object.keys(response.data)[0]]['version'])
      for (let cam in response.data) {
        setCameras({
          [cam]: {
            state: response.data[cam].state,
            name: response.data[cam].name,
            url: response.data[cam].url, 
            file: response.data[cam].file,
            filedate: response.data[cam].filedate,
            virtual: response.data[cam].virtual,
            start: response.data[cam].starttime ? new Date(response.data[cam].starttime) : null,
            end: response.data[cam].endtime ? new Date(response.data[cam].endtime) : null
          }
        })
      }
    }
  }
  useEffect(() => {
    fetchState().catch((e)=>{setLoading(false);toaster.push(errmessage, {placement: 'bottomEnd'})})
    // eslint-disable-next-line react-hooks/exhaustive-deps
  },[])

  return (
      <>
      <FlexboxGrid justify="center" className="headlist">
        <FlexboxGrid.Item colspan={7}>
          <div>Ссылка для проверки потока</div>
        </FlexboxGrid.Item>
        <FlexboxGrid.Item colspan={5}>
          <div>Тип текущего потока</div>
        </FlexboxGrid.Item>
        <FlexboxGrid.Item colspan={12}>
          <div>Дополнительные настройки</div>
        </FlexboxGrid.Item>
      </FlexboxGrid>
      <CamContext.Provider value={{ cameras, setCameras, toaster, errmessage, errastart, erraend, erraneg, erralarge, okmessage, setLoading, fetchState }}>
        {Object.keys(cameras).map((cam, idx) => (
          <VidBlock key={idx} cn={cam} />
        ))}
      </CamContext.Provider>
      </>
  );
}

export default Automate;